$text-color:       #111;
$text-color:		#333333;

$background-color: #fdfdfd;
$background-color:	#ffffff; //#fdfdfd; #F5F5F5;

$brand-color:		#2a7ae2;
$alpha:				0.87;

$grey:		#828282;
$grey-light:	lighten($grey, 40%);
$grey-dark:	darken($grey, 25%);

$blue-dark:	#194683;
$blue-dark-clear:	rgba(25, 70, 131, $alpha);
$blue-light:	#597BB3;
$green-dark:	#194638;
$pink-hot:	#FF7BB3;
$red-dark: rgb(158, 40, 18);

$cornflower: #6495ED;
$electric-lime: rgb(190,255,0);
$jungle-green: #1F9F73;

$scarlet: #FD0022;
$tangerine-vivid: #FF846A;
$unmelllow-yellow: #FFFF47;

$periwinkle: #CCCCFF;
$cerulean: #02A4D3;
$purple-mountains-majesty: #D6AEDD;

$Behr17-01: #CFCFCA;
$Behr17-09: #A4A097;
$Behr17-17: #3E4F60;
$Behr17-18: #A33935;
$MacGrey:   #CECECE;
$Grey50:    rgb(125,125,125);
$SatinBlack: #3D4041;
$BlackBoudoir: #35383A;
$ElementalGray: #8F8F8F;
$SilverStone: #A2A3A5;
