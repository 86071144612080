@charset "utf-8";
@-ms-viewport       { width: device-width; }
@import url(https://fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://fonts.googleapis.com/css?family=Rock+Salt);
@import url(https://fonts.googleapis.com/css?family=Architects+Daughter);
@import url(https://fonts.googleapis.com/css?family=Ruda);
@import url(//fonts.googleapis.com/earlyaccess/nanumbrushscript.css);

// Our variables
//$base-font-family: "Helvetica Neue", "Noto Sans KR", Helvetica, Arial, sans-serif;
$navbar-height:		38px !default;
$header-height:     $navbar-height;

$base-font-family: 'Noto Sans KR', sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   14px;
$base-font-weight: 400;
$headings-font-weight: 500;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$grid-gutter-width: 30px;
$spacing-unit:     30px;

$wiki-scheme:		navbar-inverse;
$sync-scheme:		navbar-default;

@import "color_keys";

// Width of the content area
$content-width:    800px;

$phone:            375px;
$phone-lg:         480px;
$sm:               576px;
$on-palm:            $sm;
$md:               768px;
$ipad:               $md;
$on-laptop:        800px;
$lg:               992px;
$maxlaptop:          $lg;
$xl:              1200px;
$full:               $xl;

$breadcrumb-divider: quote(">");

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "base",
    "layout",
    "colors",
    "headerColor",
    "header",
    "syntax-highlighting"
;
