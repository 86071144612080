/**
 * Reset some basic elements
 */
html {
	height: 100%;
	font-family: $base-font-family;
	font-size: $base-font-size;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
	height: 100%;
    padding: 0;
    margin: 0;
	overflow-y: scroll;
 	font-size: $base-font-size;
	font-family: $base-font-family;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	line-height: $base-line-height;

    color: $text-color;
    background-color: $background-color;
}

blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}

p {
  margin: 0 0 10px;
}

small, .small {
	font-size: 80%;
}

/**
 * Headings
 */
h1, .h1 {
  font-size: 2em;
  margin: 0.4em 0 0.2em;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
  @media only screen and (min-width: $phone) and (max-width: $phone-lg - 1) {font-size: 1.8em;}
  @media only screen and (max-width: $phone - 1) {font-size: 1.6em;}
}
h2, .h2 {
  font-size: 1.75em;
  margin: 0.3em 0 0.1em;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
  @media only screen and (min-width: $phone) and (max-width: $phone-lg - 1) {font-size: 1.6em;}
  @media only screen and (max-width: $phone - 1) {font-size: 1.5em;}
}
h3, .h3 {
  font-size: 1.5em;
}
h4, .h4 {
  font-size: 1.25em;
}
h5, .h5 {
  font-size: 1em;
}
h6, .h6 {
  font-size: 0.75em;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: inherit;
    font-weight: $headings-font-weight;
	line-height: $base-line-height;
	color: inherit;
}
h1 small, .h1 small, h1 .small, .h1 .small,
h2 small, .h2 small, h2 .small, .h2 .small,
h3 small, .h3 small, h3 .small, .h3 .small,
h4 small, .h4 small, h4 .small, .h4 .small,
h5 small, .h5 small, h5 .small, .h5 .small,
h6 small, .h6 small, h6 .small, .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777777;
}

h1 small, .h1 small, h1 .small, .h1 .small,
h2 small, .h2 small, h2 .small, .h2 .small,
h3 small, .h3 small, h3 .small, .h3 .small {
  font-size: 65%;
}

h4 small, .h4 small, h4 .small, .h4 .small,
h5 small, .h5 small, h5 .small, .h5 .small,
h6 small, .h6 small, h6 .small, .h6 .small {
  font-size: 80%;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}

/**
 * Blockquotes
 */
blockquote {
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;
    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
code {
	font-size: 1em;
	padding: 2px 4px;
	font-size: 90%;
	color: #c7254e;
	background-color: #f9f2f4;
	border-radius: 4px;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

pre {
    font-size: 15px;
    border-radius: 3px;
    padding: 8px 12px;
    overflow-x: auto;

    code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
 /*
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}
*/


/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {
    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
    }
}


/*  from style.css */
.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .lead {
    font-size: 21px;
  }
}

mark {
  background: #ff0;
  color: #000;
}
mark,
.mark {
  background-color: #fcf8e3;
  padding: .2em;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-muted {
  color: #777777;
}
.text-primary {
  color: #337ab7;
}
a.text-primary:hover {
  color: #286090;
}
.text-success {
  color: #3c763d;
}
a.text-success:hover {
  color: #2b542c;
}
.text-info {
  color: #31708f;
}
a.text-info:hover {
  color: #245269;
}
.text-warning {
  color: #8a6d3b;
}
a.text-warning:hover {
  color: #66512c;
}
.text-danger {
  color: #a94442;
}
a.text-danger:hover {
  color: #843534;
}
.bg-primary {
  color: #fff;
  background-color: #337ab7;
}
a.bg-primary:hover {
  background-color: #286090;
}
.bg-success {
  background-color: #dff0d8;
}
a.bg-success:hover {
  background-color: #c1e2b3;
}
.bg-info {
  background-color: #d9edf7;
}
a.bg-info:hover {
  background-color: #afd9ee;
}
.bg-warning {
  background-color: #fcf8e3;
}
a.bg-warning:hover {
  background-color: #f7ecb5;
}
.bg-danger {
  background-color: #f2dede;
}
a.bg-danger:hover {
  background-color: #e4b9b9;
}
.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee;
}

/**
 * Lists
 */
ul, ol {
	margin-left: $spacing-unit*2/3;
	margin-top: 0;
	margin-bottom: 10px;
}

li {
    > ul,
    > ol {
         margin-bottom: 10px;
    }
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 10px;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
dl {
  margin-top: 0;
  margin-bottom: 20px;
}
dt,
dd {
  line-height: 1em;
  line-height: 1.42857143; /* GC Conflict */
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}

.pull-left {
  float: left !important;
}


/*=========================================================
   text decorations etc
===========================================================*/

/* custom rules ----- to ---------- */
.hr1 {border-top-width: 2pt;}
.hr2 {border-top-width: 3pt;}
.hr3 {border-top-width: 4pt;}
.hr4 {border-top-width: 5pt;}
.hr5 {border-top-width: 6pt;}
.hr6 {border-top-width: 7pt;}
